var Drupal = Drupal || {};

(function ($) {

  drawSlider();

  function drawSlider() {
    $('.slider').slick({
      arrows: true,
      prevArrow: "<div class=\"slick-prev far fa-angle-left\"></div>",
      nextArrow: "<div class=\"slick-next far fa-angle-right\"></div>"
    });

    console.log("Hello");
  }

})(jQuery);
