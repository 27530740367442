var Drupal = Drupal || {};

(function ($) {

  try {
    document.createEvent("TouchEvent");
    $('body').addClass('touch');
  } catch (e) {
    $('body').addClass('no-touch');
  }

  var showNavigation = false;
  Drupal.behaviors.toggleNav = {
    attach: function (context, settings) {
      $('.nav--toggle').on('click', function () {
        showNavigation = !showNavigation;
        if (showNavigation) {
          $('body').addClass('overlay--active');
          $(this).addClass('nav--toggle--open');
        } else {
          $('body').removeClass('overlay--active');
          $('.nav--toggle').removeClass('nav--toggle--open');
        }
      });
    }
  };


  setAnimationFramePolyfill();

  var header = $('header.header'),
    headerHeight = 0,
    elTop = 0,
    dHeight = 0,
    windowHeight = 0,
    scrollCurrent = 0,
    scrollBefore = 0,
    scrollDiff = 0;

  requestAnimationFrame(drawHeader);

  function drawHeader() {
    headerHeight = header.outerHeight();
    dHeight = $(document).height();
    windowHeight = $(window).height();
    scrollCurrent = $(window).scrollTop();
    scrollDiff = scrollBefore - scrollCurrent;
    elTop = parseInt(header.css('top')) + scrollDiff;

    if (scrollCurrent >= headerHeight){//10) { // on top
      header.removeClass("not-filled");
    } else {
      header.addClass("not-filled");
    }

    if (scrollCurrent <= 0) { // on top
      header.css('top', 0);
    } else if (scrollDiff > 0) { // Scroll up, header slide in
      header.css('top', elTop > 0 ? 0 : elTop);
    } else if (scrollDiff < 0) { // Scroll down
      if (scrollCurrent + windowHeight >= dHeight - headerHeight) {
        header.css('top', (elTop = scrollCurrent + windowHeight - dHeight) < 0 ? elTop : 0);
      } else {
        header.css('top', Math.abs(elTop) > headerHeight ? -headerHeight : elTop);
      }
    }

    scrollBefore = scrollCurrent;


    requestAnimationFrame(drawHeader);
  }


  function setAnimationFramePolyfill() {
    var lastTime = 0;
    var vendors = ['webkit', 'moz'];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
      window.cancelAnimationFrame =
        window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = function (callback, element) {
        var currTime = Date.now();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));
        var id = window.setTimeout(function () { callback(currTime + timeToCall); },
          timeToCall);
        lastTime = currTime + timeToCall;
        return id;
      }
    }

    if (!window.cancelAnimationFrame) {
      window.cancelAnimationFrame = function (id) {
        clearTimeout(id);
      }
    }
  } // setAnimationFramePolyfill

})(jQuery);
